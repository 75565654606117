import React from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import Loading from '@components/Loading';
import Title from '@components/Title';

import ContactInformation from './ContactInformation';
import ContactUsForm from './ContactUsForm';

import {
  ContactUsFormType,
  getSupportCenterRecipientBySubject,
  getTestRecipientBySubject,
} from '@domain/contact';

import { useNotifications } from '@use-cases/notifications';

import { useContactUsMutation } from '@repositories/contact/hooks/useContactUsMutation';

import { useTranslation } from '@external/react-i18next';
import { log } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

const ContactUs: React.FC = () => {
  const { t } = useTranslation();
  const [contactUs, { loading }] = useContactUsMutation();
  const { addError, addSuccess } = useNotifications();

  const handleSubmit = async (
    contactForm: ContactUsFormType,
    recaptchaToken: Maybe<string>
  ) => {
    const { email, subject } = contactForm;
    const langcode = 'en';
    const supportCenterEmail =
      process.env.GATSBY_ENV === 'prod'
        ? getSupportCenterRecipientBySubject(t, subject)
        : getTestRecipientBySubject(t, subject);
    let mailSent = false;

    log({
      level: LogLevel.Info,
      message: `ContactUs::Sending mail to ${email} and ${supportCenterEmail}`,
    });

    if (email && supportCenterEmail) {
      const response = await contactUs({
        variables: {
          contactForm,
          email,
          supportCenterEmail,
          langcode,
          recaptchaToken,
        },
      });
      log({
        level: LogLevel.Info,
        message: `ContactUs::Backend Response for ${email}:  ${response?.data?.contactUs}`,
      });

      mailSent = !response?.data?.contactUs;
    }

    if (mailSent) {
      addSuccess(
        t(
          'contact.us.form.success-message',
          'Thank you for contacting us, Your information has been saved successfully'
        )
      );
    } else {
      addError(
        t(
          'contact.us.form.error-message',
          'An error occured while submitting contact information'
        )
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto flex justify-center">
      <div className="tablet:container tablet:max-w-6xl">
        <h2 className="mt-10 px-8" data-testid="title">
          <Title className="h2">
            {t('contact.us.header.title', 'Contact Us')}
          </Title>
        </h2>
        <section className="flex flex-wrap justify-between">
          <div className="max-w-lg px-8 grow">
            <p>
              <span className="mr-3">
                {t(
                  'contact.us.header.info',
                  'Please fill out this form so we can better serve you.We welcome your feedback and questions'
                )}
              </span>
            </p>
            <ContactUsForm handleSubmit={handleSubmit} />
          </div>
          <div className="max-w-lg px-8 grow">
            <ContactInformation />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
